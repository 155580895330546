@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

body {
    font-family: 'Orbitron', sans-serif;
    margin: 0;
    padding:20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #0d0f26;
    background-image: linear-gradient(147deg, #0d0f26 0%, #16193b 74%);
    color: #fff;
}

.form-container {
    width: 450px;
    padding: 40px;
    background-color: #2b2d47;
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    animation: float 6s ease-in-out infinite;
}

@keyframes float {
    0%, 100% { transform: translatey(0px); }
    50% { transform: translatey(-10px); }
}

header {
    background-color: #4e44ce;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    font-size: 1.4em;
    margin-bottom: 20px;
    border-radius: 12px 12px 0 0;
    border-radius: 12px;
}

h1, h3 {
    font-size: 1.3em;
    margin-bottom: 10px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="text"],
input[type="url"],
input[type="number"],
textarea,
select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: none;
    border-radius: 6px;
    font-size: 1em;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
    outline: none;
    transition: background-color 0.3s ease;
}

input[type="text"]:focus,
input[type="url"]:focus,
input[type="number"]:focus,
textarea:focus,
select:focus {
    background-color: rgba(255, 255, 255, 0.3);
}

input[type="checkbox"] {
    margin-right: 10px;
}

button {
    width: 100%;
    padding: 12px;
    background-color: #4e44ce;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 1.1em;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
    background-color: #3a2fb8;
    transform: translateY(-3px);
}

.gold-button {
    width: 100%;
    padding: 12px;
    background: linear-gradient(145deg, #f0c27b, #4d2c15);
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 1.1em;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    border: 2px solid #000;
    position: relative;
    overflow: hidden;
}

.gold-button::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.1);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.gold-button:hover {
    background: linear-gradient(145deg, #ffdf5d, #d4a73a);
    transform: translateY(-3px);
}

.gold-button:hover::after {
    opacity: 1;
}

.total-price {
    font-size: 1.2em;
    color: #ff4d4d;
    text-align: center;
    margin-top: 20px;
}
